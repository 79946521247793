<mat-toolbar color="primary" style="position: fixed; background: rgb(112,128,144) !important;">
  <button *ngIf="user" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <!-- <img src="../assets/images/InfoClip.JPG" class="projImage" alt="info clip image"> -->
  InfoClip
  <span></span>
  <span class="spacer"></span>
  <div *ngIf="user">
    <a mat-list-item href="javascript:;" routerLink="accessdenied">Home</a>
    <a mat-list-item href="javascript:;">{{user.UserName}}</a>
  </div>
</mat-toolbar>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" #appDrawer mode="over" opened="false">
    <mat-nav-list>
      <app-menu-list *ngFor="let item of navItems" [item]="item" (navToggle)="toggleNav()"></app-menu-list>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content></ng-content>
    <!-- Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>