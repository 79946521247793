<app-navmenu>
  <div class="marg">
    <ngx-ui-loader [fgsColor]="demoService.config.fgsColor" [fgsPosition]="demoService.config.fgsPosition"
      [fgsSize]="demoService.config.fgsSize" [fgsType]="demoService.config.fgsType"
      [bgsColor]="demoService.config.bgsColor" [bgsOpacity]="demoService.config.bgsOpacity"
      [bgsPosition]="demoService.config.bgsPosition" [bgsSize]="demoService.config.bgsSize"
      [bgsType]="demoService.config.bgsType" [gap]="demoService.config.gap"
      [logoPosition]="demoService.config.logoPosition" [logoSize]="demoService.config.logoSize"
      [logoUrl]="demoService.config.logoUrl" [overlayColor]="demoService.config.overlayColor"
      [pbColor]="demoService.config.pbColor" [pbDirection]="demoService.config.pbDirection"
      [pbThickness]="demoService.config.pbThickness" [hasProgressBar]="demoService.config.hasProgressBar"
      [text]="demoService.config.text" [textColor]="demoService.config.textColor"
      [textPosition]="demoService.config.textPosition"></ngx-ui-loader>
    <div class="mat-elevation-z8">
      <router-outlet></router-outlet>
    </div>
  </div>
</app-navmenu>