import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

/**
 * Prefixes all requests with updating loader status, calculating elapsed time and error handling .
 */
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    constructor(private toastr: ToastrService,
        private loaderService: NgxUiLoaderService) {
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loaderService.start();
        const started = Date.now();
        return next.handle(request).pipe(
            finalize(() => {
                const elapsed = Date.now() - started;
                //console.log("time elpased for url:" + request.url + " is :" + elapsed + " ms");
                this.loaderService.stop();
            }), 
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    //console.log(event);
                    /* if(event)
                        this.noti.success("successfully loaded"); */
                }
                //return event;
            }),
            catchError(err => this.errorHandler(err)))
    }
    // Customize the default error handler here if needed
    private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
        //console.log(response);
        //this.noti.error(response['error']['message'])
        throw response;
    }
}