import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray, ValidatorFn, AbstractControl, FormControlName } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { InfoService } from './info.service';
import { Label } from 'src/app/models/label.model';
import { AppService } from 'src/app/services/app.service';
import { AddInfo_url, base_url, UploadAttachment_url } from 'src/app/config/api-urls';
import { Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Info } from 'src/app/models/info.model';

@Component({
  selector: 'app-info',
  templateUrl: 'info.component.html',
  styleUrls: ['info.component.css'],  
  providers: [InfoService]
})

export class InfoComponent implements OnInit {
  // Listing Code
  displayedColumns: string[] = ['InfoID', 'CategoryName', 'InfoTitle','view', 'edit', 'delete'];
  dataSource = new MatTableDataSource<Info[]>();
  selection = new SelectionModel<Info>(false, []);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  
  // AddEdit Code
  addInfoForm: FormGroup;
  categories: any[];
  filteredCategories: any;
  selectedCategory: any;
  functionType: string = "ADD";
  isFormSubmitted = false;
  selectedIndex = 0;
  attachFormData: FormData;
  user: any;
  labelsData: any;
  Labels: FormArray = this.formBuilder.array([]);
  currentInfoDetails: any;
  currentAttachmentURL: string;
  currentAttachmentURL_PDF: string;

  constructor(
    private addInfoService: InfoService,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private loaderService: NgxUiLoaderService) {
  
  }

  ngOnInit() {
    this.getCategories();
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.getInfoList({UserID: this.user.UserID});
    this.attachFormData = new FormData();    
    this.addInfoForm = this.formBuilder.group({
        CategoryName: new FormControl(null, Validators.compose([Validators.required, RequireMatch ] )),
        InfoTitle: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(100)])),
        conLabels: this.Labels,
        Attachment: new FormControl(null)
      });

      this.filteredCategories = this.addInfoForm.controls['CategoryName'].valueChanges.pipe(
        startWith(''), map(name => name ? this.filterCategories(name) : this.ClearSelection())
  )}
  
  getCategories() {
    this.addInfoService.getCategories()
    .then(data => {
        this.categories = data;
    });
  }

  filterCategories(val: string) {
    return val ? this.categories.filter((s) => {
        return new RegExp(val, 'gi').test(s.CategoryName);
    }) : this.categories;

  }  

  ClearSelection() {
      this.labelsData = [];
      this.resetLabels ();
      return this.categories;
  }

  displayCategory(category: any) {
    return category ? category.CategoryName : '';
  }  

  selectedOption(event) {
      this.resetLabels();
      const selectedValue = event.option.value;
      this.addInfoService.loadLabels(selectedValue)
        .then(data => {
          this.labelsData = data;
          for (var k=0; k < this.labelsData.length; k++)
          {
            const row = this.formBuilder.group({
              'LabelID': [this.labelsData[k].LabelID, []],
              'LabelName': [this.labelsData[k].LabelName, []],
              'LabelValue': ['', []]
            });
            this.Labels.push(row);      
          }
          this.selectedCategory = selectedValue;
        });      
  }

  resetLabels () {
    const arr = <FormArray>this.Labels;
    arr.controls = [];    
  }
  
  AddInfo() {
    this.isFormSubmitted = true;
    if (this.addInfoForm.valid) {  

      this.attachFormData.append('UserID', this.user.UserID);
      this.attachFormData.append('InfoTitle', this.addInfoForm.controls['InfoTitle'].value);
      this.attachFormData.append('CategoryID', this.selectedCategory.CategoryID);
      this.attachFormData.append('Labels', JSON.stringify(this.addInfoForm.controls['conLabels'].value));

      this.addInfoService.AddInfo(this.attachFormData).then(res => 
      {
          if (res) {
            this.clearFormData();
            this.toastr.success('Info added successfully');
            this.getInfoList({UserID: this.user.UserID});
          } else {
            this.toastr.error('ERROR! while adding info details');
          }
      });   

    }    
  }

  private clearFormData() {
    this.addInfoForm.reset();
    this.labelsData = [];
    this.resetLabels ();    
    this.attachFormData = null;
    this.isFormSubmitted = false;
  }

  // GetLabelValues() {
  //   var arrayControl = (this.Labels as FormArray); 
  //   var lbl = [];
  //   for (let cntrl in arrayControl.controls)
  //   {
  //     //lbl.push({LabelID: cntrl.id, LabelValue: cntrl.Value});      
  //     console.log(arrayControl.at(0));
  //   }
  //   //return arrayControl;
  // }
  
  onFileSelect(event){
    var file = event.target.files[0];
    var fileName = file.name;
    var ext = fileName.split('.').pop().toLowerCase();
    if (
          (ext == 'jpeg') || 
          (ext == 'jpg') ||
          (ext == 'png') ||
          (ext == 'pdf') 
       ) {
        this.attachFormData = new FormData();    
        this.attachFormData.append('Attachment',file);   
      } else {
          alert('Please upload jpg/png/pdf file only.');
          return false;
        }    
  }  

  // ********************* Listing Methods ************************
  private getInfoList(UserID) {
    this.addInfoService.getInfoList(UserID).then(data => {
      //console.log(data);
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
    })
  }

  private editInfo(event?) {
    this.editFormData(event);
    this.functionType = 'EDIT'; 
    //this.getLabels({CategoryID: this.categoryForm.controls.CategoryID.value});
    this.selectedIndex = 1;
  }

  private editFormData(formData) {
    this.addInfoForm.reset();
    this.addInfoForm.controls.InfoID.setValue(formData.InfoID);
    this.addInfoForm.controls.InfoTitle.setValue(formData.InfoTitle);
    this.addInfoForm.controls.CategoryID.setValue(formData.CategoryID);
    this.addInfoForm.controls.CategoryName.setValue(formData.CategoryName);
    this.addInfoForm.controls.InfoDetails.setValue(formData.InfoDetails);
  }

  private deleteRow(formData) {
    this.addInfoService.deleteRow(formData).then(res => {
      this.getInfoList({UserID: this.user.UserID});
    })   

  }

  sortGrid(event) {
    //console.log(event);
  }

  pageChange(event) {
    //console.log(event);
  }

  ViewInfoDetails(info) {
    //console.log(info);
    this.currentInfoDetails = info.InfoDetails;
    this.currentAttachmentURL = '';
    this.currentAttachmentURL_PDF = '';
    if (info.Attachment != '') {
      var ext = info.Attachment.split('.').pop().toLowerCase();
      if ( (ext == 'jpeg') || (ext == 'jpg') || (ext == 'png') ) 
      {
          this.currentAttachmentURL = base_url + "/Attachments/" + info.Attachment;
      } 
      else if (ext == 'pdf') 
      {
        this.currentAttachmentURL_PDF = base_url + "/Attachments/" + info.Attachment;
      }   
    }
  }
}

export function RequireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (typeof selection === 'string') {
        return { incorrect: true };
    }
    return null;
}


