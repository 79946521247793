<label class="LabelHead">Info</label>
<mat-tab-group animationDuration="0ms">
  <mat-tab label="List" class="LabelTitle"> 

    <mat-card fxFlex="700px" >    
      <mat-card-content class="mat-elevation-z4">
          <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortGrid($event)">

              <!-- Info Id Column -->
              <ng-container matColumnDef="InfoID">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                  <td mat-cell *matCellDef="let item; let i = index">
                      {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}
                  </td>
              </ng-container>

              <!-- Category Name Column -->
              <ng-container matColumnDef="CategoryName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 30%"> Category </th>
                <td mat-cell *matCellDef="let element"> {{element.CategoryName}} </td>
              </ng-container>

              <!-- Info Title Column -->
              <ng-container matColumnDef="InfoTitle">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 70%"> Info Title </th>
                  <td mat-cell *matCellDef="let element"> {{element.InfoTitle}} </td>
              </ng-container>

              <!-- View Column -->
              <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef> View </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button color="accent" (click)="ViewInfoDetails(element)">
                        <mat-icon class="mat-18">remove_red_eye</mat-icon>
                    </button>
                </td>
              </ng-container>

              <!-- Edit Column -->
              <ng-container matColumnDef="edit">
                  <th mat-header-cell *matHeaderCellDef> Edit </th>
                  <td mat-cell *matCellDef="let element">
                      <button mat-icon-button color="accent" (click)="editInfo(element)">
                          <mat-icon class="mat-18">border_color</mat-icon>
                      </button>
                  </td>
              </ng-container>

              <!-- Delete Column -->
              <ng-container matColumnDef="delete">
                  <th mat-header-cell *matHeaderCellDef> Delete </th>
                  <td mat-cell *matCellDef="let element">
                      <button mat-icon-button color="warn" (click)="deleteRow(element)">
                          <mat-icon class="mat-18">delete</mat-icon>
                      </button>
                  </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                  (click)="selection.toggle(row); ViewInfoDetails(row);" [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
                  (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
              </tr>
                            
          </table>
          <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons #paginatorRef></mat-paginator>
      </mat-card-content>
    </mat-card> 

    <mat-card fxFlex="700px" *ngIf="currentInfoDetails || currentAttachmentURL">    
      <mat-card-content class="mat-elevation-z4" *ngIf="currentInfoDetails">
        <table border="1">
          <tr class="LabelText">
              <td colspan="2">
                  <mat-label class="LabelText"><u>Info Details</u> :</mat-label>
              </td>
          </tr>
          <tr class="LabelText" *ngFor="let detail of currentInfoDetails">
              <td style="width: 30%;">
                  <input type="text" class="LabelText" value="{{detail.LabelName}}" readonly style="border: 0; text-align: right;" tabindex="-1"> 
              </td>
              <td>
                <input type="text" class="LabelText" value="{{detail.LabelValue}}" readonly style="border: 0; text-align: left;" tabindex="-1"> 
              </td>
          </tr>
        </table>
      </mat-card-content>
      
      <mat-card-content class="mat-elevation-z4" *ngIf="currentAttachmentURL">
        <table border="1">
          <tr class="LabelText">
              <td>
                  <mat-label class="LabelText"><u>Attachment Preview</u> :</mat-label>
              </td>
              <td style="text-align: right;">
                <mat-label class="LabelText">
                  <a [href]="currentAttachmentURL" target="_blank">Download</a>
                </mat-label>
              </td>
          </tr>
          <tr class="LabelText">
            <td style="text-align: center;" colspan="2">
                <img [src]="currentAttachmentURL" height="200">
            </td>
          </tr>
        </table>        
      </mat-card-content>      

      <mat-card-content class="mat-elevation-z4" *ngIf="currentAttachmentURL_PDF">
        <table border="1">
          <tr class="LabelText">
              <td>
                  <mat-label class="LabelText"><u>Attachment Preview</u> :</mat-label>
              </td>
              <td style="text-align: right;">
                <mat-label class="LabelText">
                  <a [href]="currentAttachmentURL_PDF" target="_blank">Preview</a>
                </mat-label>
            </td>
          </tr>
          <tr class="LabelText">
            <td style="text-align: center;" colspan="2">
              <div class="container">
                <pdf-viewer [src]="currentAttachmentURL_PDF"
                            [original-size]="false"></pdf-viewer>
              </div>              
            </td>
          </tr>
        </table>        
      </mat-card-content>      
      
    </mat-card> 

  </mat-tab>
  <mat-tab label="Add" class="LabelTitle">

    <mat-card>
      <form [formGroup]="addInfoForm" autocomplete="off" novalidate (ngSubmit)="AddInfo()">
    
      <table align="center" class="mat-elevation-z4">
        <tr>
          <td align="right" width="40%">
              <label class="LabelText" for="CategoryName">Info Category :</label>
          </td>
          <td align="left">
              <input type="text" class="InputControl LabelText" id="CategoryName" formControlName="CategoryName"
              [formControl]="addInfoForm.controls['CategoryName']"
              placeholder="Select Category" 
              [matAutocomplete]="auto"
              maxlength="100">
              <mat-autocomplete class="LabelText" #auto="matAutocomplete" [displayWith]="displayCategory" (optionSelected)="selectedOption($event)" >
                <mat-option class="LabelText" *ngFor="let category of filteredCategories | async" [value]="category" (click)="selectedItem=category" >
                  {{category.CategoryName}}
                </mat-option>
              </mat-autocomplete> 
              <span class="ErrorText" *ngIf="(addInfoForm.controls.CategoryName.touched || isFormSubmitted) && addInfoForm.controls.CategoryName.errors?.required">
                Category is required
              </span>
          </td>      
        </tr>  
    
        <tr>
          <td align="right">
            <label for="InfoTitle" class="LabelText" >Info Title :</label>
          </td>
          <td align="left">
            <input type="text" class="InputControl LabelText" id="InfoTitle" formControlName="InfoTitle" 
            [formControl]="addInfoForm.controls['InfoTitle']"
            placeholder="Info Title" maxlength="100">
            <span class="ErrorText" *ngIf="(addInfoForm.controls.InfoTitle.touched || isFormSubmitted) && addInfoForm.controls.InfoTitle.errors?.required">
              Title is required
            </span>       
          </td>
        </tr>  

        <tr formArrayName="conLabels" *ngFor="let label of Labels.controls; let i = index;" >
          <ng-container [formGroupName]="i">
            <td align="right" width="40%">
              <input type="text" class="LabelText" formControlName="LabelName" readonly style="border: 0; text-align: right;" tabindex="-1"> 
            </td>
            <td align="left">
              <input type="text" class="InputControl LabelText" formControlName="LabelValue"  maxlength="50"> 
            </td>
          </ng-container>
        </tr>

        <tr>
          <td align="right">
            <label for="Attachment" class="LabelText" >Attachment :</label>
          </td>
          <td align="left">
            <input type="file" id="Attachment" formControlName="Attachment" (change)="onFileSelect($event)" class="InputControl LabelText" 
            [formControl]="addInfoForm.controls['Attachment']" accept="image/jpeg, image/png, application/pdf" />
            <label for="Attachment" class="LabelText" >[Accepts only jpeg, jpg, png and pdf]</label>
          </td>
        </tr>  

      </table>

      
      <br>
      <table align="center">
        <tr>
          <td align="center">
            <button class="btn btn-primary btnColor" type="submit">
              Save
            </button>
          </td>       
        </tr>      
      </table>

    </form>
    </mat-card> 
    
  </mat-tab>
</mat-tab-group>

<!-- <pre>{{ this.addInfoForm.value | json }}</pre> -->