import { Injectable } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { GetLabels_url, DeleteLabel_url } from 'src/app/config/api-urls';

@Injectable()

export class LabelService {
  constructor(
    private appService : AppService
  ) {}

  getLabelList(): Promise<any> {
    return this.appService.getApiMethod(GetLabels_url).toPromise();
  }

  saveLabel(url: string, formValues: any): Promise<any> {
    return this.appService.postApiMethod(url, formValues).toPromise();          
  }

  // Method to Delete Row Data
  deleteRow(rowData): Promise<any> {
    let condition = '?LabelID=' + rowData.LabelID;
    return this.appService.deleteApiMethod(DeleteLabel_url + condition).toPromise();
  }

  // CheckLabelNameExists(strLabel: string): Observable<any | undefined>  {
  //   var param = {LabelName: strLabel};
  //   return this.appService.getApiMethod_Param(CheckLabelNameExists_url, param).pipe();
  // }

}

