import { Injectable } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { GetInfoByUser_url } from 'src/app/config/api-urls';

@Injectable()

export class HomeService {

  constructor(
    private appService : AppService
  ) {}

  getInfoList(UserID: any): Promise<any> {
    return this.appService.getApiMethod_Param(GetInfoByUser_url, UserID).toPromise();
  }

}

