import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidatorFn, NG_ASYNC_VALIDATORS } from "@angular/forms";
import { Observable, of } from "rxjs";
import { map, debounceTime, take, switchMap } from "rxjs/operators";
import {  user_get_by_email, 
          CheckLabelNameExists_url, 
          CheckCategoryNameExists_url 
        } from 'src/app/config/api-urls';
import { AppService } from 'src/app/services/app.service';
//import { jsonpCallbackContext } from "@angular/common/http/src/module";

function isEmptyInputValue(value: any): boolean {
  return value === null || value.length === 0;
}

@Injectable({
  providedIn: "root"
})

export class CustomValidator {
  constructor(
          private appService: AppService
  ) {}

  existingEmailValidator(initialValue: string = ""): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<{ [key: string]: any } | null>
      | Observable<{ [key: string]: any } | null> => {
      if (isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === initialValue) {
        return of(null);
      } else {
        var param = {UserEmail: control.value};
        return control.valueChanges.pipe(
          debounceTime(2000),
          take(1),
          switchMap(_ =>
            this.appService.getApiMethod_Param(user_get_by_email, param)
              .pipe(
                map(res => res ? { existingEmail: { value: control.value } } : null
                )
              )
          )
        );

      }
    };
  }

  existingLabelValidator(intLabelID: number = 0): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<{ [key: string]: any } | null>
      | Observable<{ [key: string]: any } | null> => {
        let param: any = { LabelID: intLabelID,
                           LabelName: control.value};
        return control.valueChanges.pipe(
          debounceTime(2000),
          take(1),
          switchMap(_ =>
            this.appService.getApiMethod_Param(CheckLabelNameExists_url, param)
              .pipe(
                    map(res => res ? { existingLabel: { value: control.value } } : null
                )
              )
          )
        );

    };
  }  

  existingCategoryValidator(intCategoryID: number = 0): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<{ [key: string]: any } | null>
      | Observable<{ [key: string]: any } | null> => {
        let param: any = { CategoryID: intCategoryID, 
                           CategoryName: control.value
                          };
        return control.valueChanges.pipe(
          debounceTime(2000),
          take(1),
          switchMap(_ =>
            this.appService.getApiMethod_Param(CheckCategoryNameExists_url, param)
              .pipe(
                    map(res => res ? { existingCategory: { value: control.value } } : null
                )
              )
          )
        );

    };
  }  

}
