<div class="container">
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4 col-md-offset-3">
      <div class="registerHeader">
        <div class="row">
          <div class="col-md-12 text-center">
            <h3>Register User</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <form class="form-horizontal" [formGroup]="registerForm" id="registerForm" name="registerForm"
            (submit)="RegisterUser()">

            <div class="form-group">
              <div class="input-group">
                <span class="input-group-addon">
                  <i class="glyphicon glyphicon-user"></i>
                </span>
                <input type="text" formControlName="UserName" class="form-control" id="UserName" placeholder="User Name" maxlength="100">
              </div>
              <small *ngIf="(registerForm.controls.UserName.errors?.required) && submitButton" class="text-danger">
                Enter user name
              </small>
            </div>

            <div class="form-group">
              <div class="input-group">
                <span class="input-group-addon">
                  <i class="glyphicon glyphicon-user"></i>
                </span>
                <input type="email" formControlName="Email" class="form-control" id="Email" placeholder="Email" maxlength="100">
              </div>
              <div>              
                <small *ngIf="(registerForm.controls.Email.errors?.required) && submitButton" class="text-danger">
                  Enter user email
                </small>
                <small *ngIf="(registerForm.controls.Email.errors?.pattern) && submitButton" class="text-danger">
                  Enter a valid email address
                </small>
                <small *ngIf="registerForm.controls.Email.errors?.existingEmail" class="text-danger">
                  Email address already exists
                </small>
              </div>
            </div>

            <div class="form-group">
              <div class="input-group">
                <span class="input-group-addon">
                  <i class="glyphicon glyphicon-user"></i>
                </span>
                <input type="text" formControlName="Mobile" class="form-control" id="Mobile" placeholder="Mobile" maxlength="13">
              </div>
              <small *ngIf="(registerForm.controls.Mobile.errors?.required) && submitButton" class="text-danger">
                Enter user mobile
              </small>
              <small *ngIf="(registerForm.controls.Mobile.errors?.pattern) && submitButton" class="text-danger">
                Mobile number format (for ex.): "91-9876543210"
              </small>
            </div>            

            <div class="form-group">
              <div class="input-group">
                <span class="input-group-addon">
                  <i class="glyphicon glyphicon-lock"></i>
                </span>
                <input type="password" formControlName="Password" class="form-control" id="Password" placeholder="Password">
              </div>
              <small *ngIf="(registerForm.controls.Password.errors?.required) && submitButton"
                class="text-danger">Enter password</small>
            </div>

            <div>
              <button class="btn btn-primary btnRegister" type="submit">
                  Register
              </button>
            </div>

            <div>
              <a routerLink="/login">Back to Login</a><br/><br/>
            </div>
            
          </form>
        </div>
      </div>
    </div>
  </div>

</div>