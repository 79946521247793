<table style="width: 100%;"  border="1">
    <tr>
        <td style="width: 60%; vertical-align: top;">

        <table mat-table class="inner-mat-table" [dataSource]="dataInRowDetails">
            <tr class="LabelText" *ngFor="let detail of dataInRowDetails">
            </tr>
            <ng-container matColumnDef="LabelName">
                <th mat-header-cell *matHeaderCellDef style="width: 30%; text-align: right;"> Label </th>
                <td mat-cell *matCellDef="let detail" align="right"> {{detail.LabelName}} </td>
            </ng-container>
            <ng-container matColumnDef="space">
                <th mat-header-cell *matHeaderCellDef style="width: 2%;">  </th>
                <td mat-cell *matCellDef="let detail" align="center"> : </td>
            </ng-container>            
            <ng-container matColumnDef="LabelValue">
                <th mat-header-cell *matHeaderCellDef> Value </th>
                <td mat-cell *matCellDef="let detail" align="left"> {{detail.LabelValue}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedInfoDetails"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedInfoDetails;"></tr>
        </table>

    </td>
    <td>

        <table>
            <tr>
                <td style="text-align: center;" *ngIf="currentAttachmentURL">
                    <a [href]="currentAttachmentURL" target="_blank">
                        <img [src]="currentAttachmentURL" height="200">
                    </a>
                </td>            
                <td style="text-align: center;" *ngIf="currentAttachmentURL_PDF">
                    <a [href]="currentAttachmentURL_PDF" target="_blank">
                        <mat-icon class="md-48">picture_as_pdf</mat-icon>
                    </a>
                    <!-- <pdf-viewer [src]="currentAttachmentURL_PDF" [original-size]="false"></pdf-viewer>     -->
                </td>
            </tr>
        </table>      

        </td>

    </tr>
</table>      
