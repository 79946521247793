import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomValidator } from '../../shared/custom.validator';
import { RegisterService } from './register.service';

@Component({
  selector: 'app-register',
  templateUrl: 'register.component.html',
  styleUrls: ['register.component.css'],
  providers: [RegisterService]
})

export class RegisterComponent implements OnInit {

    public registerForm: FormGroup;
    public submitButton: boolean = false;

    constructor(
                private router: Router,
                private registerService: RegisterService,
                private customValidator: CustomValidator,
                private toastr: ToastrService,
                private loaderService: NgxUiLoaderService,
                private formBuilder: FormBuilder
              ) {
    }

    emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
    mobilePattern = "^[0-9]{2}-[0-9]{10}$";

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            UserName: ['', Validators.compose([Validators.required, 
                                               Validators.maxLength(100) ]) ],
            Email:    ['', Validators.compose([Validators.required, 
                                               Validators.maxLength(100), 
                                               Validators.pattern(this.emailPattern)]), 
                                               this.customValidator.existingEmailValidator() ],
            Mobile:   ['', Validators.compose([Validators.required, 
                                               Validators.maxLength(13), 
                                               Validators.pattern(this.mobilePattern) ]) ],
            Password: ['', Validators.compose([Validators.required]) ]
        });
    }

    RegisterUser()
    {
      this.submitButton = true;
      if (this.registerForm.valid) {  
          this.registerService.RegisterUser(this.registerForm.value).then(res => {
            this.submitButton = false;
            this.toastr.success('User added successfully');
            this.router.navigate(['login']);
        });        
      }
    }
  
}

