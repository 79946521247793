import { Injectable } from "@angular/core";
import { AppService } from 'src/app/services/app.service';
import { user_register_url } from 'src/app/config/api-urls';

@Injectable()

export class RegisterService {
  constructor(
    private appService : AppService
  ) {}

  // GetUserByEmail(strEmail: string): Observable<any | undefined>  {
  //   var userParam = {UserEmail: strEmail};
  //   return this.appService.getApiMethod_Param(user_get_by_email, userParam).pipe();
  // }

  RegisterUser(formData: any): Promise<any> {
    return this.appService.postApiMethod(user_register_url, formData).toPromise();
  }


}
