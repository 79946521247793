<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css">
<label class="LabelHead">Info</label>
<mat-tab-group animationDuration="0ms">
  <mat-tab label="Message Board" class="LabelTitle"> 

    <mat-card fxFlex="100%" >    
      <mat-card-content class="mat-elevation-z4">
          <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortGrid($event)" multiTemplateDataRows>

              <!-- Info Id Column -->
              <ng-container matColumnDef="InfoID">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                  <td mat-cell *matCellDef="let element; let i = dataIndex;">
                    {{ (i+1) + (paginatorRef.pageIndex * paginatorRef.pageSize) }}
                  </td>
              </ng-container>

              <!-- Category Name Column -->
              <ng-container matColumnDef="CategoryName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:30%"> Category </th>
                <td mat-cell *matCellDef="let element"> {{element.CategoryName}} </td>
              </ng-container>

              <!-- Info Title Column -->
              <ng-container matColumnDef="InfoTitle">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:70%"> Info Title </th>
                  <td mat-cell *matCellDef="let element"> {{element.InfoTitle}} </td>
              </ng-container>

              <!-- View Column -->
              <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef> View </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button color="accent" (click)="ViewInfoDetails(element)">
                        <mat-icon class="mat-18">remove_red_eye</mat-icon>
                    </button>
                </td>
              </ng-container>

              <!-- sms Column -->
              <ng-container matColumnDef="sms">
                  <th mat-header-cell *matHeaderCellDef> SMS </th>
                  <td mat-cell *matCellDef="let element">
                      <button mat-icon-button color="accent" (click)="smsInfo(element)">
                          <mat-icon class="mat-18">sms</mat-icon>
                      </button>
                  </td>
              </ng-container>

              <!-- Email Column -->
              <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef> EMail </th>
                  <td mat-cell *matCellDef="let element">
                      <button mat-icon-button color="warn" (click)="emailInfo(element)">
                          <mat-icon class="mat-18">email</mat-icon>
                      </button>
                  </td>
              </ng-container>

              <!-- WhatsApp Column -->
              <ng-container matColumnDef="whatsapp">
                <th mat-header-cell *matHeaderCellDef> WhatsApp </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button color="warn" (click)="whatsappInfo(element)">
                        <i class="fa fa-whatsapp fa_custom"></i>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="InfoDetails">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                   <div class="expand-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                        <app-infoDetailView style="width: 100%;"
                            [dataInRowDetails]="dataInfoDetailView"
                            [currentAttachmentURL]="currentAttachmentURL"
                            [currentAttachmentURL_PDF]="currentAttachmentURL_PDF"
                          >
                        </app-infoDetailView>

                   </div>
                </td>
             </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                class="expand-element-row"
                [class.expand-expanded-row]="expandedElement === row"              
                (click)="expandedElement=row; selection.toggle(row); ViewInfoDetails(row);" 
                [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['InfoDetails']" class="expand-detail-row"></tr>

          </table>
          <mat-paginator #paginatorRef [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons ></mat-paginator>
      </mat-card-content>
    </mat-card> 

    <!-- <mat-card fxFlex="700px" *ngIf="currentInfoDetails || currentAttachmentURL">    
      <mat-card-content class="mat-elevation-z4" *ngIf="currentInfoDetails">
        <table border="1">
          <tr class="LabelText">
              <td colspan="2">
                  <mat-label class="LabelText"><u>Info Details</u> :</mat-label>
              </td>
          </tr>
          <tr class="LabelText" *ngFor="let detail of currentInfoDetails">
              <td style="width: 30%;">
                  <input type="text" class="LabelText" value="{{detail.LabelName}}" readonly style="border: 0; text-align: right;" tabindex="-1"> 
              </td>
              <td>
                <input type="text" class="LabelText" value="{{detail.LabelValue}}" readonly style="border: 0; text-align: left;" tabindex="-1"> 
              </td>
          </tr>
        </table>
      </mat-card-content>
      
      <mat-card-content class="mat-elevation-z4" *ngIf="currentAttachmentURL">
        <table border="1">
          <tr class="LabelText">
              <td>
                  <mat-label class="LabelText"><u>Attachment Preview</u> :</mat-label>
              </td>
              <td style="text-align: right;">
                <mat-label class="LabelText">
                  <a [href]="currentAttachmentURL" target="_blank">Download</a>
                </mat-label>
              </td>
          </tr>
          <tr class="LabelText">
            <td style="text-align: center;" colspan="2">
                <img [src]="currentAttachmentURL" height="200">
            </td>
          </tr>
        </table>        
      </mat-card-content>      

      <mat-card-content class="mat-elevation-z4" *ngIf="currentAttachmentURL_PDF">
        <table border="1">
          <tr class="LabelText">
              <td>
                  <mat-label class="LabelText"><u>Attachment Preview</u> :</mat-label>
              </td>
              <td style="text-align: right;">
                <mat-label class="LabelText">
                  <a [href]="currentAttachmentURL_PDF" target="_blank">Preview</a>
                </mat-label>
            </td>
          </tr>
          <tr class="LabelText">
            <td style="text-align: center;" colspan="2">
              <div class="container">
                <pdf-viewer [src]="src"
                    [original-size]="false"
                    ></pdf-viewer>           
              </div>              
            </td>
          </tr>
        </table>        
      </mat-card-content>      
      
    </mat-card> -->
    
    </mat-tab>

    <mat-tab label="Message History" class="LabelTitle"> 
        <mat-card fxFlex="700px" >    
            <mat-card-content class="mat-elevation-z4">

            </mat-card-content>              
        </mat-card>
    </mat-tab>
</mat-tab-group>

<!-- <pre>{{ element | json }}</pre> -->