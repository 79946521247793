import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { RegisterComponent } from './components/register/register.component';
import { CategoryComponent } from './components/category/category.component';
import { LabelComponent } from './components/label/label.component';
import { InfoComponent } from './components/info/info.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule) },
  {
    path: 'home', component: HomeComponent, 
    data: {
      breadCrumb: [
        { title: 'Home', path: 'home' }
      ]
    }
  },
  {
    path: 'info', component: InfoComponent, 
    data: {
      breadCrumb: [
        { title: 'Info', path: 'info' }
      ]
    }
  },   
  {
    path: 'register', component: RegisterComponent, 
    data: {
      breadCrumb: [
        { title: 'Register', path: 'register' }
      ]
    }
  },
  {
    path: 'category', component: CategoryComponent, 
    data: {
      breadCrumb: [
        { title: 'Category', path: 'category' }
      ]
    }
  },
  {
    path: 'label', component: LabelComponent, 
    data: {
      breadCrumb: [
        { title: 'Label', path: 'label' }
      ]
    }
  },  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
