<label class="LabelHead">Label</label>
<mat-tab-group animationDuration="0ms" (selectedTabChange)="tabChange($event)" [selectedIndex]="selectedIndex">
    <mat-tab label="List" class="LabelTitle"> 

    <mat-card>    
        <mat-card-content class="mat-elevation-z4">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortGrid($event)">

                <!-- Label Id Column -->
                <ng-container matColumnDef="LabelID">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                    <td mat-cell *matCellDef="let element"> {{element.LabelID}} </td>
                </ng-container>

                <!-- Label Name Column -->
                <ng-container matColumnDef="LabelName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 70%"> Label Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.LabelName}} </td>
                </ng-container>

                <!-- Edit Column -->
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef> Edit </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="accent" (click)="editLabel(element)">
                            <mat-icon class="mat-18">border_color</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <!-- Delete Column -->
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef> Delete </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="warn" (click)="deleteRow(element)">
                            <mat-icon class="mat-18">delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    (click)="selection.toggle(row); " [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
                    (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
                </tr>
  
            </table>
            <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
        </mat-card-content>    

    </mat-card> 

    </mat-tab>
    <mat-tab label="Add" class="LabelTitle">
        <mat-card fxFlex="700px" fxFlex.xs="100%">

            <form [formGroup]="labelForm" autocomplete="off" novalidate (ngSubmit)="saveLabel(labelForm.value)"
                fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
                <mat-card-content class="mat-elevation-z4">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Label Name" formControlName="LabelName"
                            id="LabelName" maxlength="50">
                        <mat-error class="ErrorText"
                            *ngIf="(labelForm.controls.LabelName.touched || isFormSubmitted) && labelForm.controls.LabelName.errors?.required">
                            Label Name is required
                        </mat-error>
                        <mat-error class="ErrorText" *ngIf="labelForm.controls.LabelName.errors?.existingLabel">
                            Label Name already exists
                        </mat-error>              
                    </mat-form-field>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button class="btnColor" type="submit">Save</button>
                </mat-card-actions>
            </form>
        </mat-card>
        
    </mat-tab>
</mat-tab-group>