import { BrowserModule } from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ToastrModule } from 'ngx-toastr';

import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION,
  PB_DIRECTION, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './inceptors/jwt.inceptor';
import { ErrorInterceptor } from './inceptors/error.inceptor';
import { LoaderInterceptor } from './inceptors/loader.inceptor';

import { ReplacePipe } from './shared/replace.pipe';

import { NavmenuComponent } from './navmenu/navmenu.component';
import { NavMenuListComponent } from './nav-menu-list/nav-menu-list.component';
import { HomeComponent } from './components/home/home.component';
import { RegisterComponent } from './components/register/register.component';
import { CategoryComponent } from './components/category/category.component';
import { LabelComponent } from './components/label/label.component';
import { InfoComponent } from './components/info/info.component';
import { InfoDetailViewComponent } from './components/infoDetailView/infoDetailView.component';
import { MatCardModule } from '@angular/material/card';

  const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsColor: '#eb6b1d',
    // bgsOpacity: 0.5,
    // bgsPosition: POSITION.bottomCenter,
    // bgsSize: 60,
    bgsType: "ball-spin-clockwise-fade-rotating",
    fgsColor: '#eb6b1d',
    // fgsPosition: POSITION.centerCenter,
    // fgsSize: 60,
    // fgsType: SPINNER.chasingDots,
    // logoUrl: 'assets/angular.png',
    pbColor: '#eb6b1d',
    // pbDirection: PB_DIRECTION.leftToRight,
    // pbThickness: 5,
    // text: 'Welcome to ngx-ui-loader',
    // textColor: '#FFFFFF',
    // textPosition: POSITION.centerCenter
  };


@NgModule({
  declarations: [
    AppComponent,
    ReplacePipe,

    NavmenuComponent,
    NavMenuListComponent,
    HomeComponent,
    RegisterComponent,
    CategoryComponent,
    LabelComponent,
    InfoComponent,
    InfoDetailViewComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    AppRoutingModule,
    MaterialModule,
    MatCardModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule, ReactiveFormsModule, 
    LayoutModule, 
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule, // import this module for showing loader automatically when navigating between app routes
    //NgxUiLoaderHttpModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ], 
  entryComponents: [ ],
})
export class AppModule { }
