<label class="LabelHead">Category</label>
<mat-tab-group animationDuration="0ms" (selectedTabChange)="tabChange($event)" [selectedIndex]="selectedIndex">
    <mat-tab label="List" class="LabelTitle"> 

    <mat-card>    
        <mat-card-content class="mat-elevation-z4">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortGrid($event)">

                <!-- Category Id Column -->
                <ng-container matColumnDef="CategoryID">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                    <td mat-cell *matCellDef="let element"> {{element.CategoryID}} </td>
                </ng-container>

                <!-- Category Name Column -->
                <ng-container matColumnDef="CategoryName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 20%"> Category Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.CategoryName}} </td>
                </ng-container>

                <!-- Category Name Column -->
                <ng-container matColumnDef="LabelName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Labels </th>
                    <td mat-cell *matCellDef="let element"> {{element.LabelName}} </td>
                </ng-container>

                <!-- Edit Column -->
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef> Edit </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="accent" (click)="editCategory(element)">
                            <mat-icon class="mat-18">border_color</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <!-- Delete Column -->
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef> Delete </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="warn" (click)="deleteRow(element)">
                            <mat-icon class="mat-18">delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    (click)="selection.toggle(row); " [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
                    (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
                </tr>
  
            </table>
            <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
        </mat-card-content>    

    </mat-card> 

    </mat-tab>
    <mat-tab label="Add" class="LabelTitle">
        <mat-card fxFlex="700px" fxFlex.xs="100%">

            <form [formGroup]="categoryForm" autocomplete="off" novalidate (ngSubmit)="saveCategory(categoryForm.value)"
                fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
                <mat-card-content class="mat-elevation-z4">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Category Name" formControlName="CategoryName"
                            id="CategoryName" maxlength="50">
                        <mat-error class="ErrorText"
                            *ngIf="(categoryForm.controls.CategoryName.touched || isFormSubmitted) && categoryForm.controls.CategoryName.errors?.required">
                            Category Name is required
                        </mat-error>
                        <mat-error class="ErrorText" *ngIf="categoryForm.controls.CategoryName.errors?.existingCategory">
                            Category Name already exists
                        </mat-error>                        
                    </mat-form-field>
                    <div *ngIf="(labelsData)" >
                        <mat-label class="LabelText"><u>Select applicable labels</u> :</mat-label>
                        <mat-grid-list cols="3" rowHeight="20px" gutterSize="5px" >
                            <mat-grid-tile class="LabelText" *ngFor="let label of labelsData; let i = index">
                                <div class="text-inside-grid">
                                    <input type="checkbox" value="{{label.LabelID}}" id="{{label.LabelID}}" name="{{label.LabelName}}" [checked]="label.isSelected == 1" (change)="onCheckboxChange($event)" />&nbsp;&nbsp;{{label.LabelName}}
                                </div>
                            </mat-grid-tile>
                        </mat-grid-list>
                        <mat-error class="ErrorText" *ngIf="(categoryForm.controls.labels.touched || isFormSubmitted) && categoryForm.controls.labels.errors?.required">
                            Atleast one Label should be selected
                        </mat-error>
                    </div> 
                </mat-card-content>

                <mat-card-content *ngIf="(selectedLabelsData)" class="mat-elevation-z4" >
                    <table>
                        <tr class="LabelText">
                            <td>
                                <mat-label class="LabelText"><u>Selected labels </u> :</mat-label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div cdkDropList class="drag-list" (cdkDropListDropped)="drop($event)">
                                    <div class="drag-box" *ngFor="let label of selectedLabelsData" cdkDrag>
                                        <input align="left" type="checkbox" value="{{label.LabelID}}" id="{{label.LabelID}}" disabled checked />&nbsp;&nbsp;{{label.LabelName}}
                                    </div>
                                </div>                                
                            </td>
                        </tr>
                        <tr class="LabelText">
                            <td>
                                <mat-label class="LabelText">
                                    <u>Note</u> : Drag & Drop items to sort
                                </mat-label>
                            </td>
                        </tr>                        
                    </table>
                </mat-card-content>

                <mat-card-actions>
                    <button class="btn btn-primary btnColor" type="submit">Save</button>
                </mat-card-actions>
            </form>
        </mat-card>
        
    </mat-tab>
</mat-tab-group>