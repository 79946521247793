import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatSort } from '@angular/material/sort';
import { LabelService } from 'src/app/components/label/label.service';
import { AddLabel_url, UpdateLabel_url } from 'src/app/config/api-urls';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from '../../shared/custom.validator';
import { SelectionModel } from '@angular/cdk/collections';
import { Label } from 'src/app/models/label.model';

@Component({
  selector: 'app-label',
  templateUrl: 'label.component.html',
  styleUrls: ['label.component.css'],  
  providers: [LabelService]
})
export class LabelComponent implements OnInit {
  
  // Listing Code
  displayedColumns: string[] = ['LabelID', 'LabelName', 'edit', 'delete'];
  dataSource = new MatTableDataSource<Label[]>();
  selection = new SelectionModel<Label>(false, []);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  //AddEdit Code
  labelForm: FormGroup;
  functionType: string = "ADD";
  isFormSubmitted = false;
  selectedIndex = 0;  
  
  constructor(
      private labelService: LabelService,
      private toastr: ToastrService,
      private loaderService: NgxUiLoaderService,
      private customValidator: CustomValidator,
      private formBuilder: FormBuilder
    ) {

  }

  ngOnInit() {
    // Listing Code
    this.getLabelList();
    this.dataSource.sort = this.sort;
    
    // AddEdit Code
    this.labelForm = this.formBuilder.group({
      LabelID: [''],
      LabelName: ['', Validators.required, 
                      this.customValidator.existingLabelValidator() ]
    })    
  }

  tabChange(tab) {
    this.selectedIndex = tab.index;
    if (this.selectedIndex == 0)
    {
      this.clearFormData();
      this.functionType == 'ADD'; 
      this.getLabelList();
    }
    else {
      this.labelForm.controls.LabelName.clearAsyncValidators();

      if (this.functionType == 'ADD') {
        this.labelForm.controls.LabelName.setAsyncValidators(
          this.customValidator.existingLabelValidator(0)
        )
      }
      else {
        this.labelForm.controls.LabelName.setAsyncValidators(
          this.customValidator.existingLabelValidator(this.labelForm.controls.LabelID.value)
        )      
      }
    }    
  }

  // ********************* Listing Methods ************************  
  private getLabelList() {
    this.labelService.getLabelList().then(data => 
      {
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
      })
  }

  private editLabel(event?) {
    this.editFormData(event);
    this.functionType = 'EDIT'; 
    this.selectedIndex = 1;
  }

  private editFormData(formData) {
    this.labelForm.controls.LabelID.setValue(formData.LabelID);
    this.labelForm.controls.LabelName.setValue(formData.LabelName);
  }

  private deleteRow(formData) {
    this.labelService.deleteRow(formData).then(res => {
      this.getLabelList();
    })
  }

  sortGrid(event) {
    //console.log(event);
  }

  pageChange(event) {
    //console.log(event);
  }

// ********************* AddEdit Methods ************************
  private clearFormData() {
    this.labelForm.reset();
    this.labelForm.controls.LabelName.clearAsyncValidators();
    this.isFormSubmitted = false;
  }

  saveLabel(formData) {
    this.isFormSubmitted = true;
    let apiUrl = '';

    if (this.labelForm.valid) {

      if (this.functionType == 'ADD') {
        apiUrl = AddLabel_url;
      }
      else {
        apiUrl = UpdateLabel_url;
      }

      this.labelService.saveLabel(apiUrl, formData).then(res => {
          this.clearFormData();
          if (this.functionType == 'EDIT') {
              this.functionType = 'ADD'
              this.selectedIndex = 0;
          }
      });

    }
  }

}