import { Injectable } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { GetCategoryLabels_List_url, GetCategoryLabels_Input_url, DeleteCategory_url } from 'src/app/config/api-urls';

@Injectable()
export class CategoryService {
  constructor(
    private appService : AppService
  ) {}

    getLabels(category: any): Promise<any> {
        return this.appService.getApiMethod_Param(GetCategoryLabels_Input_url, category).toPromise();
    }

    getCategoryList(): Promise<any> {
        return this.appService.getApiMethod(GetCategoryLabels_List_url).toPromise();
    }

    saveCategory(url: string, formValues: any): Promise<any> {
        return this.appService.postApiMethod(url, formValues).toPromise();          
      }

    // Method to Delete Row Data
    deleteRow(rowData): Promise<any> {
      let condition = '?CategoryID=' + rowData.CategoryID;
      return this.appService.deleteApiMethod(DeleteCategory_url + condition).toPromise();
    }

}

