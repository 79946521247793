import {Component, HostBinding, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-menu-list',
  templateUrl: './nav-menu-list.component.html',
  styleUrls: ['./nav-menu-list.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class NavMenuListComponent implements OnInit {
  
  expanded: boolean = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item :any;
  @Input() depth: any;
  @Output() navToggle = new EventEmitter<number>();;

  constructor(//public navService: NavService,
     public router: Router) {
      if (this.depth === undefined) {
          this.depth = 0;
      }
  }

  ngOnInit() {
    if (this.item.route && this.router.url) {
      this.expanded = this.router.url.indexOf(`/${this.item.route}`) === 0;
      this.ariaExpanded = this.expanded;
    }
  }

  onItemSelected(item) {
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
      if(item.route == 'login'){
        this.logout();
      }
      this.navToggle.emit();
      //this.navService.closeNav();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

  logout() {
      localStorage.clear();
  }

  depthNavToggle(){
    this.navToggle.emit();
  }
}
