import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { base_url } from 'src/app/config/api-urls';
import { Info, InfoDetail } from 'src/app/models/info.model';
import { HomeService } from './home.service';

@Component({
  selector: 'home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.css'],  
  providers: [HomeService],
  animations: [
    trigger('detailExpand', [
    state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
    state('expanded', style({height: '*'})),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]),
],  
})

export class HomeComponent implements OnInit {
  src = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  // Listing Code
  displayedColumns: string[] = ['InfoID', 'CategoryName', 'InfoTitle','view', 'sms', 'email', 'whatsapp'];
  dataSource = new MatTableDataSource<Info[]>();
  selection = new SelectionModel<Info>(false, []);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  currentInfoDetails: any;
  currentAttachmentURL: string;
  currentAttachmentURL_PDF: string;
  user: any;
  dataInfoDetailView: InfoDetail[] = new Array<InfoDetail>();

  constructor(
    private homeService: HomeService,
  ){}

  ngOnInit(){
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    if (this.user != null) {
      this.getInfoList({UserID: this.user.UserID});    
    }    
  }

  ngAfterViewInit() {

  }

  private getInfoList(UserID) {
    this.homeService.getInfoList(UserID).then(data => {
      //console.log(data);
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
    })
  }

  sortGrid(event) {
    //console.log(event);
  }

  pageChange(event) {
    //console.log(event);
  }

  ViewInfoDetails(info?) {
    //console.log(info);
    this.currentInfoDetails = info.InfoDetails;
    this.dataInfoDetailView = info.InfoDetails;
    //console.log(this.dataInfoDetailView);
    this.currentAttachmentURL = '';
    this.currentAttachmentURL_PDF = '';
    if (info.Attachment != '') {
      var ext = info.Attachment.split('.').pop().toLowerCase();
      if ( (ext == 'jpeg') || (ext == 'jpg') || (ext == 'png') ) 
      {
          this.currentAttachmentURL = base_url + "/Attachments/" + info.Attachment;
      } 
      else if (ext == 'pdf') 
      {
        this.currentAttachmentURL_PDF = base_url + "/Attachments/" + info.Attachment;
      }   
    }
  }
  
  smsInfo (info) {

  }

  emailInfo (info) {

  }

  whatsappInfo (info) {

  }
}
