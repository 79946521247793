import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private toastr: ToastrService,
                private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            // this.toastr.error(err.statusText);
            // this.toastr.error(err.status);
            // this.toastr.error(err.message);
            if ((err.status === 400) || (err.status === 401)) {
                this.toastr.error("Authentication Error! Check the Connectivity/Credentials");
                localStorage.clear();
                this.router.navigate(['login']);
            } 
            else {
                this.toastr.error(err.message);
            }
            //const error = err.message || err.statusText;
            return throwError(err);
        }));
    }
}

