import { Injectable } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import {  GetCategories_url, 
          GetLabelsByCategory_url, 
          AddInfo_url, 
          GetInfoByUser_url, 
          DeleteInfo_url} from 'src/app/config/api-urls';

@Injectable()

export class InfoService {

  constructor(
    private appService : AppService
  ) {}

  getCategories(): Promise<any> {
    return this.appService.getApiMethod(GetCategories_url).toPromise();
  }
  
  loadLabels(category: any): Promise<any> {
    return this.appService.getApiMethod_Param(GetLabelsByCategory_url, category).toPromise();
  }

  AddInfo(formValues: FormData): Promise<any> {
    return this.appService.postApiMethodWithFileUpload(AddInfo_url, formValues).toPromise();          
  }

  getInfoList(UserID: any): Promise<any> {
    return this.appService.getApiMethod_Param(GetInfoByUser_url, UserID).toPromise();
  }

    // Method to Delete Row Data
    deleteRow(rowData): Promise<any> {
      let condition = '?InfoID=' + rowData.InfoID;
      return this.appService.deleteApiMethod(DeleteInfo_url + condition).toPromise();
    }  
}

