// Data Service with the Methods of NavMenu and BreadCrumb // 

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { promise } from 'protractor';
import { base_url } from '../config/api-urls';

@Injectable({
    providedIn: 'root',
})
export class AppService {

    tableName: string
    public loading: boolean;

    headers: HttpHeaders;
    params: HttpParams;

    private BASE_URL = base_url
    private BASE_API_URL = base_url + '/api/';
    
    constructor(private http: HttpClient) {

    }

    // Setting Headers and passing Token
    getHeaders() {
        const _headers = new HttpHeaders();
        const headers = _headers.append('Content-Type', 'application/json');
                                //.append("Authorization", "bearer " + localStorage.getItem("token"));
        var options = {
            headers: headers,
            params: null
        };
        return options;  
    }

    postToken(url, data): any {      
        const body = new HttpParams()          
            .set('UserName', data.UserName)    
            .set('Password', data.Password)    
            .set('grant_type', data.grant_type)          
        return this.http.post(this.BASE_URL + url, body.toString(), {observe: 'response',    
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },    
        });    
      }    

    postApiMethod(url, data): Observable<{}> {
        let options = this.getHeaders();
        return this.http.post(this.BASE_API_URL + url, data, options)
    }    
    
    postApiMethodWithFileUpload(url, data) {
        const _headers = new HttpHeaders();
        const headers = _headers.append('enctype', 'multipart/form-data');
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        var options = {
            headers: headers
        };
        
        return this.http.post(this.BASE_API_URL + url, data, options)
    }    

    getApiMethod(url) {
        let options = this.getHeaders();
        return this.http.get(this.BASE_API_URL + url, options);
    }
    
    getApiMethod_Param(url, data) {
        let options = this.getHeaders();
        if(data)
        {
            options.params = data;  
        }
        return this.http.get(this.BASE_API_URL + url, options);
    }     

    deleteApiMethod(url) {
        let options = this.getHeaders();
        return this.http.delete(this.BASE_API_URL + url, options)
    }   

    postFileUpload(url, data) {
        const _headers = new HttpHeaders();
        const headers = _headers.append('enctype', 'multipart/form-data');
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        var options = {
            headers: headers
        };
        
        return this.http.post(this.BASE_API_URL + url, data, options)
    }


}