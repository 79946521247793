import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatSort } from '@angular/material/sort';
import { CategoryService } from 'src/app/components/category/category.service';
import { AddCategory_url, UpdateCategory_url } from 'src/app/config/api-urls';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Label } from 'src/app/models/label.model'
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { CustomValidator } from '../../shared/custom.validator';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-category',
  templateUrl: 'category.component.html',
  styleUrls: ['category.component.css'],  
  providers: [CategoryService]
})
export class CategoryComponent implements OnInit {

  // Listing Code
  displayedColumns: string[] = ['CategoryID', 'CategoryName', 'LabelName', 'edit', 'delete'];
  dataSource = new MatTableDataSource<any[]>();
  selection = new SelectionModel<any>(false, []);
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // AddEdit Code
  categoryForm: FormGroup;
  functionType: string = "ADD";
  isFormSubmitted = false;
  selectedIndex = 0;
  labelsData: Label[];
  selectedLabelsData: Label[];

  constructor(
      private categoryService: CategoryService,
      private toastr: ToastrService,
      private loaderService: NgxUiLoaderService,
      private customValidator: CustomValidator,
      private formBuilder: FormBuilder
    ) {

  }

  ngOnInit() {
    // Listing Code
    this.getCategoryList();
    this.dataSource.sort = this.sort;

    // AddEdit Code
    this.categoryForm = this.formBuilder.group({
      CategoryID: [''],
      CategoryName: ['', Validators.required ],
      labels: this.formBuilder.array([], [Validators.required])
    });
    this.getLabels({CategoryID: 0});
  }

  tabChange(tab) {
    this.selectedIndex = tab.index;
    if (this.selectedIndex == 0)
    {
      this.clearFormData();
      this.functionType == 'ADD'; 
      this.getCategoryList();
    } 
    else {
      this.categoryForm.controls.CategoryName.clearAsyncValidators();

      if (this.functionType == 'ADD') {
        this.categoryForm.controls.CategoryName.setAsyncValidators(
          this.customValidator.existingCategoryValidator(0)
        )
      }
      else {
        this.categoryForm.controls.CategoryName.setAsyncValidators(
          this.customValidator.existingCategoryValidator(this.categoryForm.controls.CategoryID.value)
        )      
      }
    }
  }

  // ********************* Listing Methods ************************
  private getCategoryList() {
    this.categoryService.getCategoryList().then(data => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
    })
  }

  private editCategory(event?) {
    this.editFormData(event);
    this.functionType = 'EDIT'; 
    this.getLabels({CategoryID: this.categoryForm.controls.CategoryID.value});
    this.selectedIndex = 1;
  }

  private editFormData(formData) {
    this.categoryForm.reset();
    this.categoryForm.controls.CategoryID.setValue(formData.CategoryID);
    this.categoryForm.controls.CategoryName.setValue(formData.CategoryName);
  }

  private deleteRow(formData) {
    this.categoryService.deleteRow(formData).then(res => {
      this.getCategoryList();
    })   
     
    // this.confirmDialogService.confirmThis("Are you sure to delete?", function () {  
    //       this.categoryService.deleteRow(formData).then(res => {
    //         this.getCategoryList();
    //       })    
    // }, function () {  
    //       alert("No clicked");  
    // })  
  }

  sortGrid(event) {
    //console.log(event);
  }

  pageChange(event) {
    //console.log(event);
  }

  // ********************* AddEdit Methods ************************
  private getLabels(intCategoryID : any) {
    this.categoryService.getLabels(intCategoryID).then(data => {
      this.labelsData = data;
      this.selectedLabelsData = this.labelsData.filter(l => l.isSelected == true)
                                               .sort((a, b) => a.SortIndex - b.SortIndex);
      this.onCheckboxLoad();
    })
  }

  private onCheckboxLoad() {
    this.clearLabelsFormArray();
    let checkArray: FormArray = this.categoryForm.controls.labels as FormArray;
    for (let label of this.selectedLabelsData.sort((a, b) => a.SortIndex - b.SortIndex)) {
        checkArray.push(new FormControl(label.LabelID));
    }
  }  

  private onCheckboxChange(e) {
    if (e.target.checked) {
        var lbl = new Label();
        lbl.LabelID = e.target.value;
        lbl.LabelName = e.target.name;
        lbl.isSelected = true;
        lbl.SortIndex = this.selectedLabelsData.length + 1;
        this.selectedLabelsData.push(lbl);
    } else {
        for (var i=0; i <= this.selectedLabelsData.length-1; i++)
        {
          if (this.selectedLabelsData[i].LabelID == e.target.value)
          {
            this.selectedLabelsData.splice(i, 1);
            break;
          }
        }
    }

    let checkArray: FormArray = this.categoryForm.controls.labels as FormArray;
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }

  }  
  
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedLabelsData, event.previousIndex, event.currentIndex);
    this.reOrderLabelsData(event.previousIndex, event.currentIndex);
  }

  private reOrderLabelsData(intPrevIndex, intCurIndex) {
    let checkArray: FormArray = this.categoryForm.controls.labels as FormArray;
    //const prevItem = this.selectedLabelsData[intPrevIndex];
    const curItem = this.selectedLabelsData[intCurIndex];

    // console.log(intPrevIndex);
    // console.log(intCurIndex);
    // console.log(prevItem);
    // console.log(curItem);
    console.log(this.selectedLabelsData);

    //if dragging up (PrevIndex > CurIndex), first insert before delete
    //else dragging down (PrevIndex < CurIndex), first delete before insert
    if (intPrevIndex > intCurIndex) {
      checkArray.insert(intCurIndex, new FormControl(curItem.LabelID));

      let i: number = 0;
        checkArray.controls.forEach((item: FormControl) => {
            if ((intCurIndex != i) && (item.value == curItem.LabelID)) {
              checkArray.removeAt(i);
              return;
            }
            i++;
        });
    } else {
      let i: number = 0;
        checkArray.controls.forEach((item: FormControl) => {
            if ((intCurIndex != i) && (item.value == curItem.LabelID)) {
              checkArray.removeAt(i);
              return;
            }
            i++;
        });

        checkArray.insert(intCurIndex, new FormControl(curItem.LabelID));
    }
    console.log(checkArray.value);
  }

  private clearFormData() {
    this.categoryForm.reset();
    this.clearLabelsFormArray();
    this.categoryForm.controls.CategoryName.clearAsyncValidators();
    this.isFormSubmitted = false;
    this.getLabels({CategoryID: 0});
  }

  private clearLabelsFormArray() {
    let checkArray: FormArray = this.categoryForm.controls.labels as FormArray;
    let i: number = 0;
    checkArray.controls.forEach((item: FormControl) => {
      checkArray.removeAt(i);
      i++;
    });
  }

  saveCategory(formData) {
    this.isFormSubmitted = true;
    let apiUrl = '';

    if (this.categoryForm.valid) {

      if (this.functionType == 'ADD') {
        apiUrl = AddCategory_url;
      }
      else {
        apiUrl = UpdateCategory_url;
      }

      // console.log(this.selectedLabelsData);
      // console.log(formData);

      this.categoryService.saveCategory(apiUrl, formData).then(res => {
          this.clearFormData();
          if (this.functionType == 'EDIT') {
              this.functionType = 'ADD'
              this.selectedIndex = 0;
          }
      });
      
    }
  }

    
}
