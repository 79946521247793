import { Component, ChangeDetectorRef, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-navmenu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.scss']
})
export class NavmenuComponent {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  user: string;
  @ViewChild('drawer', { static: true }) drawer;
  navItems: any[];

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, 
              private ref: ChangeDetectorRef, private activeRoute :ActivatedRoute) { }

  toggleNav() {
    this.drawer.toggle();
  }

  ngOnInit() {
    console.log(this.activeRoute);
    this.navItems = this.navMenuItems;
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    if (!this.user) {
      this.router.navigate(['login']);
    }else{
      this.router.events.subscribe(data=>{
          if (data instanceof NavigationStart) {
              let url = data.url;
              if(url == "/"){
                this.router.navigate(['login']);
              }
          }
      })
  }
    
  }

  ngAfterViewChecked() {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    if(!this.user && this.navItems.length > 0){
      this.navItems = [];
    }else if(this.user)
      this.navItems = this.navMenuItems;
    this.ref.detectChanges();
  }

  logout() {
    this.toggleNav()
    localStorage.removeItem('currentUser');
    this.user = null;
  }

  navMenuItems= [
    {
      displayName: 'Home',
      iconName: 'home',
      route: 'home',
    },    
    {
      displayName: 'Info',
      iconName: 'library_add',
      route: 'info',
    },
    {
      displayName: 'Category',
      iconName: 'account_tree',
      route: 'category',
    },
    {
      displayName: 'Label',
      iconName: 'list',
      route: 'label',
    },
    {
      displayName: 'Log Out',
      iconName: 'undo',
      route: 'login'
    }
  ];
}
