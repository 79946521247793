
//export const base_url = 'http://localhost:49567/';
export const base_url = 'https://apexinfosystems.com/';

export const token_url = 'TokenLogin';
//export const login_url = 'User/Login';
export const user_get_by_email = 'User/GetUserByEmail';
export const user_register_url = 'User/AddUser';

export const frgt_pwd_url =  'forgotpassword';
export const chng_pwd_url =  'resetpassword';

export const AddCategory_url = 'Category/AddCategory';
export const UpdateCategory_url = 'Category/UpdateCategory';
export const DeleteCategory_url = "Category/DeleteCategory";
export const GetCategories_url = 'Category/GetCategories';
export const CheckCategoryNameExists_url = 'Category/CheckCategoryNameExists';
export const GetCategoryLabels_List_url = 'Category/GetCategoryLabels_List';
export const GetCategoryLabels_Input_url = 'Category/GetCategoryLabels_Input';

export const AddLabel_url = 'Label/AddLabel';
export const UpdateLabel_url = 'Label/UpdateLabel';
export const DeleteLabel_url = 'Label/DeleteLabel';
export const GetLabels_url = 'Label/GetLabels';
export const CheckLabelNameExists_url = 'Label/CheckLabelNameExists';
export const GetLabelsByCategory_url = 'Label/GetLabelsByCategory'

export const AddInfo_url = 'Info/AddInfo'
export const UpdateInfo_url = 'Info/UpdateInfo'
export const DeleteInfo_url = 'Info/DeleteInfo'
export const GetInfoByUser_url = 'Info/GetInfoByUser'

export const UploadAttachment_url = 'Attachment/UploadAttachment';