import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-infoDetailView',
  templateUrl: 'infoDetailView.component.html',
  styleUrls: ['infoDetailView.component.css']
})

export class InfoDetailViewComponent implements OnInit {
  constructor() {}
  
  @Input() dataInRowDetails;
  @Input() currentAttachmentURL;
  @Input() currentAttachmentURL_PDF;

  displayedInfoDetails: string[] = [
    'LabelName',
    'space',
    'LabelValue'
  ]

  ngOnInit() {
      //console.log(this.dataInRowDetails);
  }


}

